<template>
  <div
    class="main"
    :class="{ 'neutral' : $vuetify.breakpoint.smAndDown }"
  >
    <div class="sidebar neutral">
      <div class="d-flex align-center">
        <logo-group
          class="px-6 py-6"
          :logomark="true"
          :logotype="true"
        />
        <div
          v-if="$route.name === 'register-company'"
          class="text-h6 text-decoration-underline"
          style="position: relative; left: -2rem;"
        >
          Företag
        </div>
      </div>
      <div class="flex-grow-1 d-flex align-center justify-center px-6">
        <router-view />
      </div>
      <div
        v-if="$route.name==='accounts-login'"
        class="sidebar-footer elevation-1"
      >
        <h2 class="mb-2">
          GigPort för företag
        </h2>
        <p>Effektiv kompetenssökning och matchning för företag i GigPort.</p>
        <v-btn
          color="secondary"
          class="secondary--text"
          rounded
          outlined
          x-large
          depressed
          :to="{ name: 'register-company' }"
        >
          Skapa företagskonto
        </v-btn>
      </div>
    </div>
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      class="content"
    >
      <!--

      <div style="max-width: 600px;">
        <div style="position: relative; z-index: 1; margin-bottom: 10vh;">
          Låt ditt nästa uppdrag hitta dig!
        </div>
      </div>
      -->
    </div>
    <CustomAlert
      v-show="alertVisible"
      :message="alert.message"
      :type="alert.type"
      :icon="alert.icon"
    />
  </div>
</template>

<script>
import LogoGroup from '@/common/LogoGroup.vue'
import CustomAlert from '@/common/CustomAlert.vue'
// TODO: Add responsive css for mobile and smaller screens
// TODO: Add picture to right side
export default {
  name: 'AccountsView',
  components: {
    LogoGroup,
    CustomAlert
  },
  data: () => ({
    alert: {
      message: '',
      icon: false,
      type: 'error'
    },
    alertVisible: false
  }),
  mounted () {
    this.$root.$on('errorMessage', err => {
      this.handleErrorMessage(err);
    })
    this.$root.$on('successMessage', message => {
      this.createSuccessAlert(message)
    })
  },
  beforeDestroy () {
    this.$root.$off('errorMessage')
  },
  methods: {
    handleErrorMessage(err) {
      if (err.response) {
        // client received an error response (5xx, 4xx)
        if (err.response.status === 0 || err.response.status === 500) {
          this.createErrorAlert(this.$t('Validation.something_went_wrong'));
        } else {
          const errorMessage = err.response.data.errorMessage || err.response.data;
          this.createErrorAlert(errorMessage);
        }
      } else {
        this.createErrorAlert(err.message ?? 'Something went wrong');
      }
    },
    createAlert (message, icon) {
      this.alert.message = message
      this.alert.icon = icon
      this.alertVisible = true
      setTimeout(() => {
        this.alertVisible = false
      }, 5000)
    },
    createErrorAlert (message) {
      this.alert.type = 'error'
      this.alert.message = message
      this.alertVisible = true
      setTimeout(() => {
        this.alertVisible = false
      }, 5000)
    },
    createSuccessAlert (message) {
      this.alert.type = 'success'
      this.alert.message = message
      this.alertVisible = true
      setTimeout(() => {
        this.alertVisible = false
      }, 5000)
    }
  }
}
</script>

<style>
.light-background {
  background-color: #f5f5f5;
}
.neutral--text {
  color: #333;
}

.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #e2e2e2;
}

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 540px;
  width: 100%;
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 2rem 2rem;
  max-width: 460px;
  width: 100%;
  border-radius: 24px;
  background-color: hsl(200, 50%, 30%);
  align-self: center;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .sidebar-footer {
    margin-bottom: 0;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  flex-grow: 1;
  background-image: url('~@/assets/img/splash.webp');
  background-size: cover;
  background-position: center center;
}

.content:before {
  content: '';
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  background-color: hsla(202, 100%, 25%, 0.46);
  opacity: 0.75;
  top: 0;
  left: 0;
}
</style>
